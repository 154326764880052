'use strict';
require('@apac/js/app');
require('@apac/js/pages/checkout/summary');
var tooltip = require('@apac/js/tooltip');

var validator = require('./validator');
var eform = require("./eform");

jQuery(function () {
    validator();
    eform();
    tooltip.init();
});
