"use strict";

var ajax = require('../../../app_apac_foundation/cartridge/js/ajax'),
    progress = require('./../../../app_rleu_core/cartridge/js/progress'),
    util = require('../../../app_apac_foundation/cartridge/js/util'),
    eform = require('../../../app_apac_foundation/cartridge/js/eform');

module.exports = function () {

    var isJsonString = function(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    $(document).on('click', '.js-submit-eform-btn', function (e) {
        e.preventDefault();
        if (!$(this).closest('form').validate().form()) {
            $("#termandcondition-error").addClass("error-msg");
            $("#dwfrm_eform_termandcondition-error").remove();
            $("#dwfrm_eform_transferdata-error").remove();
            return false;
        }
        var $form = $('form[id$="dwfrm_eform"]');
        var url = $form.attr('action');
        progress.show($('.js-submit-eform-btn').closest('form'));
        var optinMobile = $('#optin-mobile').is(':checked') || true;
        var socialMediaID = $('#SocialMediaID').val();
        var isUpdateAction = !!socialMediaID;
        var optinEmail = $('#optin-email').is(':checked') || true;
        var optinDirectMail = $('#optin-direct-mail').is(':checked') || true;
        var optinSocial = $('#optin-social-media').is(':checked') || true;
        var data = $form.serialize();
        if (!$('.registerTermKR').length) {
            data = data + '&optinMobile=' + optinMobile + '&optinEmail=' + optinEmail + '&optinDirectMail=' + optinDirectMail
            + '&optinSocial=' + optinSocial + '&socialMediaID=' + socialMediaID;
        } else {
            data = data + '&optinSocial=' + optinSocial + '&socialMediaID=' + socialMediaID;
        }
        var eformAge = $('select[name$="_eform_age"]');
        if (eformAge.is('[disabled=disabled]')) {
            data = data + '&' + eformAge.attr('name') + '=' + eformAge.val();
        }
        var eformCountryCode = $('select[id$="_eform_countrycode"]');
        if (eformCountryCode.is('[disabled=disabled]')) {
            data = data + '&' + eformCountryCode.attr('name') + '=' + eformCountryCode.val();
        }
        ajax.load({
            url: util.appendParamToURL(url, 'format', 'ajax'),
            type: 'POST',
            data: data,
            callback: function (data) {
                if (data && typeof data === "string" && isJsonString(data)) {
                    var rs = JSON.parse(data);
                    if (rs.status === "accountexist") {
                        $('input[name$="_eform_email"]').addClass('error').removeClass('valid');
		                $('[class*="dwfrm_eform_email"] .field-wrapper').append('<span class="error">' + Resources.EXISTING_CUSTOMER + '</span>');
                    }
                    progress.hide();
                    return false;
                }
                var socialMediaID = $(data).find('#js-socialmediaID').val();
                if (socialMediaID) {
                    $('#edit-title').find('h2[id="welcome"]').hide();
                    $('#edit-title').find('h2[id="thankyou"]').show();
                    $('.js-eform-wrapper').html(data);
                    $('#dialog-eform').dialog('close');
                    eform.generateBarcode(socialMediaID);
                    progress.hide();
                } else {
                    var alertMessage = isUpdateAction ? Resources.EFORM_UPDATE_FAILED : Resources.EFORM_SUBMIT_FAILED;
                    return window.alert(alertMessage);
                }
            }
        });
    });

    $(document).on('click', '.js-submit-eformhk-btn', function (e) {
        e.preventDefault();
        var $form = $(this).closest('form');
        if (!$form.validate().form()) {
            return false;
        }
        $("#dialog-eform").dialog({
            modal: true,
            height: 650,
            resizable: false,
            width: '80%'
        });
    });

    $(document).on('click', '.js-submit-termandcondition-btn', function (e) {
        e.preventDefault();
        if (!$(this).closest('form').validate().form()) {
            $("#termandcondition-error").addClass("error-msg");
            $("#dwfrm_eform_termandcondition-error").remove();
            $("#dwfrm_eform_transferdata-error").remove();
            return false;
        }
        var $form = $('form[id$="dwfrm_eform"]');
        var url = $form.attr('action');
        progress.show($('.js-submit-termandcondition-btn').closest('form'));
        var optinMobile = $('#optin-mobile').is(':checked') || false;
        var socialMediaID = $('#SocialMediaID').val();
        var isUpdateAction = !!socialMediaID;
        var optinEmail = $('#optin-email').is(':checked') || false;
        var optinDirectMail = $('#optin-direct-mail').is(':checked') || false;
        var optinSocial = $('#optin-social-media').is(':checked') || false;
        var data = $form.serialize() + '&optinMobile=' + optinMobile + '&optinEmail=' + optinEmail + '&optinDirectMail=' + optinDirectMail
            + '&optinSocial=' + optinSocial + '&socialMediaID=' + socialMediaID;
        var eformAge = $('select[name$="_eform_age"]');
        if (eformAge.is('[disabled=disabled]')) {
            data = data + '&' + eformAge.attr('name') + '=' + eformAge.val();
        }
        ajax.load({
            url: util.appendParamToURL(url, 'format', 'ajax'),
            type: 'POST',
            data: data,
            callback: function (data) {
                var socialMediaID = $(data).find('#js-socialmediaID').val();
                if (socialMediaID) {
                    $('#edit-title').find('h2[id="welcome"]').hide();
                    $('#edit-title').find('h2[id="thankyou"]').show();
                    $('.js-eform-wrapper').html(data);
                    $('#dialog-eform').dialog('close');
                    eform.generateBarcode(socialMediaID);
                    progress.hide();
                } else {
                    var alertMessage = isUpdateAction ? Resources.EFORM_UPDATE_FAILED : Resources.EFORM_SUBMIT_FAILED;
                    return window.alert(alertMessage);
                }
            }
        });
    });

};