'use strict';
function getOffset(arrow, target) {
    var arrowMiddle = (arrow.left + arrow.right) / 2;
    var targetMiddle = (target.left + target.right) / 2;
    var offset = targetMiddle - arrowMiddle + 2;
    return offset;
}
/* Ensure that the arrow at the bottom of the tooltip points to the target of the tooltip.
    */
function adjustArrowPlacement(event, ui) {
    try {
        var $arrow = ui.tooltip.find('.arrow');
        var target = event.originalEvent.target;
        var offset = getOffset($arrow[0].getBoundingClientRect(), target.getBoundingClientRect());
        var left = parseInt($arrow.css('left'), 10) || 0; // default to 0 if NaN.
        $arrow.css('left', left + offset);
    } catch (ignored) {
        // Nothing we can do, but don't break anything.
    }
}
/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
    try {
        $(document).tooltip('destroy');
    } catch (e) {
        // Nothing to destroy
    }

    $(document).tooltip({
        items: '.tooltip',
        show: 200,
        hide: 200,
        position: {
            my: 'center bottom-23',
            at: 'center bottom-23',
            collision: 'flipfit flip',
            using: function(position, feedback) {
                if ($(this).find('.tt-up').length > 0) {
                    position.top -= 20;
                }
                $(this).css(position);
                $('<div>')
                    .addClass('arrow')
                    .addClass(feedback.vertical)
                    .addClass(feedback.horizontal)
                    .appendTo(this);
            }
        },
        tooltipClass: 'rl-tooltip',
        content: function () {
            return $(this).find('.tooltip-content').html();
        },
        open: function (event, ui) {
            adjustArrowPlacement(event, ui);
            spanClickEvent(event, ui);
            var $element = $(event.target);
            ui.tooltip.on('click', function () {
                $element.tooltip('close');
            });
        }
    });
    
    $('.tooltip.login-details-link').tooltip({
        items: '.tooltip',
        show: 200,
        hide: 200,
        position: {
            my: 'center bottom-23',
            at: 'center bottom-23',
            collision: 'flipfit flip',
            using: function(position, feedback) {
                let adjustmentValue = 0;
                if (this.querySelector('.tt-up')) {
                    adjustmentValue = 20;
                } else if (this.querySelector('.js-tt-redesign')) {
                    adjustmentValue = parseInt(this.querySelector('.js-tt-redesign').dataset.dist || -10, 10);
                }
                if (feedback.vertical === 'top') {
                    position.top += adjustmentValue;
                } else {
                    position.top -= adjustmentValue;
                }
                $(this).css(position);
                $('<div>')
                    .addClass('arrow')
                    .addClass(feedback.vertical)
                    .addClass(feedback.horizontal)
                    .appendTo(this);
            }
        },
        tooltipClass: 'rl-tooltip',
        content: function () {
            if ($(this).data('aid')) {
                return $('#' + $(this).data('aid')).html();
            } else {
                return $(this).find('.tooltip-content').html();
            }
        },
        create: function () {
            $(this).find('.tooltip[data-aid]').each(function () {
                const content = document.getElementById(this.dataset.aid);
                if (!(content && content.textContent.replace(/\n|\r|\s/gm, '') !== '')) {
                    this.remove();
                } else {
                    this.classList.add('validated');
                }
            });
        },
        open: function (event, ui) {
            adjustArrowPlacement(event, ui);
            var $element = $(event.target);
            
            // Prevent tooltip from closing when clicking inside
            ui.tooltip.on('click', function (e) {
                e.stopPropagation();
            });
            // Keep tooltip open when hovering over it
            ui.tooltip.on('mouseenter', function () {
                clearTimeout($.data(this, 'tooltipTimer'));
            });
            // Delay closing the tooltip to allow time for hover
            ui.tooltip.on('mouseleave', function () {
                var that = this;
                $.data(this, 'tooltipTimer', setTimeout(function () {
                    $element.tooltip('close');
                }, 300));
            });
        },
        close: function(event, ui) {
            ui.tooltip.hover(function() {
                $(this).stop(true).fadeTo(100, 1);
            },
            function() {
                $(this).fadeOut('300', function() {
                    $(this).remove();
                });
            });
        }
    });
};